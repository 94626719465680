.search-bar {
  .search-input {
    border: none;
    background: url('/assets/search.svg') left center no-repeat;
    padding-left: 24px;
    width: 0;
    transition: width .2s;

    &:focus,
    &:active,
    &.filled-in {
      outline: 0;
      box-shadow: none;
      width: 50%;
    }

    &::placeholder {
      color: rgba(37, 38, 94, 0.54);
    }
  }
}
