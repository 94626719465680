@use '@fortawesome/fontawesome-svg-core/styles';

* {
  font-family: 'Lato', sans-serif;
}

body {
  color: #25265e;
  background-color: #f5f6fa;
  font-size: 14px;
}

.navbar {
  .navbar-brand {
    font-size: 19px;
    font-weight: 900;
    letter-spacing: .5px;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: #283249;
  font-weight: 500;
}

h1 {
  font-size: 32px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

small,
.small {
  font-size: 12px;
}

bold,
.bold {
  font-weight: 500;
}

.subtitle {
  color: #787993;
}

.btn-secondary {
  background-color: #787993;
  color: #ffffff;

  &:hover,
  &:active {
    background-color: #787993;
  }
}

.btn-outline-secondary {
  border-color: #787993;

  &:hover,
  &:active {
    background-color: #787993;
  }
}

.popover {
  max-width: 80%;
}

.text-soft {
  color: #787993;
}

.text-muted {
  color: rgba(37, 38, 94, 0.24) !important;
}

.drop-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}

.badge-tag {
  border-radius: 19px;
  background-color: #ffffff;
  border: solid 1px #787993;
  padding: 8px 15px;
  font-size: 12px;
  color: #787993;
  font-weight: normal;

  &.active {
    background-color: #318EF6;
    color: #ffffff;
    border-color: #ffffff;
  }

  &.assigned {
    background-color: #28a745;
    color: #ffffff;
    border-color: #ffffff;
  }

  &.badge-sm {
    padding: 6px 11px;
  }
}

a.badge-tag {
  &:hover, &:active {
    text-decoration: none;
    color: #ffffff;
    background-color: #787993;
  }
}

.data-table {
  .data-row {
    padding: 15px;
    border: 1px solid #ebeef2;
    background-color: #ffffff;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  a.data-row:hover {
    text-decoration: none;
    background-color: #f8f9fa;
  }
}

.table {
  tbody {
    tr {
    }
  }

  td {
    border-top: none;
    padding: 1rem;
    vertical-align: middle;

    &.more-options-menu {
      width: 37px;
      padding: 0;

      a {
        padding: 1rem;
        display: block;
      }
    }
  }

  &.table-sm {
    td {
      padding: 10px;
    }
  }

  &.table-borderless {
    thead {
      th {
        font-weight: normal;
        color: rgba(86, 94, 115, 0.87);
      }
    }
  }

  &.table-striped {
    tbody {
      tr:nth-of-type(odd) {
        background-color: rgba(120, 121, 147, 0.04);
      }
    }
  }
}

.modal-header {
  background-color: #318ef6;

  * {
    color: #ffffff;
  }
}

.circled {
  width: 34px;
  height: 34px;
  display: inline-block;
  line-height: 28px;
  border: solid 2px #f2f2f5;
  text-align: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.05);
}

.task-completed {
  .circled {
    color: rgba(37, 38, 94, 0.24);
  }

  .description {
    color: rgba(37, 38, 94, 0.24);
    text-decoration: rgba(37, 38, 94, 0.24) solid line-through;
  }
}

.task-promoted {
  .circled {
    border-color: #afd4ff;
  }
}

a.text-color {
  color: #25265e;
}

.scroll-y {
  overflow-y: auto;
}

.top-nav {
  a {
    padding: 27px 10px;
    display: block;
    text-align: center;
    color: rgba(37, 38, 94, 0.54);

    &.active,
    &:hover,
    &:active {
      color: #25265e;
      text-decoration: none;
    }

    &.active {
      border-bottom: 4px solid #318ef6;
    }
  }
}

.react-datepicker-wrapper {
  display: block;
}

